import { Button, CircularProgress, Dialog, DialogActions, DialogContent, DialogTitle, Grid, makeStyles, TextField, Typography } from '@material-ui/core';
import { Autocomplete } from '@material-ui/lab';
import { ListMyPassengersQuery, PassengerFragment, VehicleFieldsFragment } from 'apollo/queries/types';
import { CommunicorAirportTemplate, Maybe } from 'apollo/types';
import AddPassengerModal from 'components/AddPassengerModal/AddPassengerModal';
import DatePicker from 'components/DatePicker/DatePicker';
import VehicleSelector from 'components/VehicleSelector/VehicleSelector';
import TransportInformation from 'pages/AirportOrCityTransfer/TransportInformation/TransportInformation';
import { forwardRef, useImperativeHandle, useState, useMemo } from 'react';
import { FlightInformation } from 'type/FlightInformation';
import { TrainInformation } from 'type/TrainInformation';
import { formatLitteralTime } from 'utils/formatDate';
import { formatPrice } from 'utils/formatPrice';

interface EditAirportLineModalProps {
  transportType: string | undefined | null;
  setTransportType: (type: string | undefined | null) => void;
  flightInformation: FlightInformation;
  setFlightInformation: (information: FlightInformation) => void;
  trainInformation: TrainInformation;
  setTrainInformation: (information: TrainInformation) => void;
  onValidate: () => Promise<void>;
  selectedVehicle: number;
  setSelectedVehicle: (selected: number) => void;
  selectedDate: string;
  setSelectedDate: (selected: string) => void;
  tzValue: Maybe<string> | undefined;
  passenger: PassengerFragment | null;
  setPassenger: (name: PassengerFragment | null) => void;
  vehicles: VehicleFieldsFragment[] | undefined;
  priceline: Omit<CommunicorAirportTemplate, 'pricelistId'|'availableVehicleTypeIds'|'airportVehicleTypeIds'> | undefined;
  passengersList?: ListMyPassengersQuery;
  passengersLoading: boolean;
  alreadyTakenPassengersIds: number[];
  refetch: Function;
}

const useStyles = makeStyles({
  root: {
    color: 'black'
  }
});

const EditAirportLineModal = forwardRef((props: EditAirportLineModalProps, ref) => {

  const classes = useStyles();

  const [opened, setOpened] = useState<boolean>(false);
  const [openModal, setOpenModal] = useState<boolean>(false);

  const initialPassenger = useMemo(() => props.passenger, []);

  const handleValidate = async () => {
    try {
      await props.onValidate();
      setOpened(false);
    } catch (err) {
      console.log(err);
    }
  };

  const open = () => {
    setOpened(true);
  };

  useImperativeHandle(ref, () => ({
    open
  }));

  const price = props.priceline?.pricePerUnit || 0;


  const selectedVehicleDetails = useMemo(() => {
    if (props.vehicles !== null && props.vehicles !== undefined && props.selectedVehicle !== null) {
      const vehicle = props.vehicles.find((vehicle) => vehicle.id === props.selectedVehicle);
      return vehicle || null;
    } else {
      return null;
    }
  }, [props.vehicles, props.selectedVehicle]);

  return (
    <Dialog
      open={opened}
      onClose={() => setOpened(false)}
    >
      <DialogTitle> Editing Airport or City Transfer </DialogTitle>

      <DialogContent>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <DatePicker
              disableToolbar
              variant="inline"
              format="dd/MM/yyyy"
              margin="none"
              id="date"
              label="Date"
              value={props.selectedDate}
              onChange={(date) => date !== null && props.setSelectedDate(date.toISOString())}
              inputVariant="outlined"
              InputLabelProps={{
                shrink: true,
              }}
              fullWidth
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              id="date"
              label="Time (if available)"
              type="time"
              defaultValue={formatLitteralTime(props.selectedDate, props.tzValue)}
              onChange={(event) => {
                const value = event.target.value;
                if (value !== '') {
                  const [hours, minutes] = value.split(':');
                  const newDate = new Date(props.selectedDate);
                  newDate.setHours(parseInt(hours));
                  newDate.setMinutes(parseInt(minutes));
                  props.setSelectedDate(newDate.toISOString());
                }
              }}
              InputLabelProps={{
                shrink: true,
              }}
              variant="outlined"
              fullWidth
            />
          </Grid>
          <Grid item xs={12}>
            <Autocomplete
              disablePortal
              id="combo-box-demo"
              options={[{ id: null }, ...props.passengersList?.searchResUsers || []]}
              value={props.passenger}
              getOptionLabel={
                (passenger) => {
                  if (passenger.id === null) {
                    return 'Create a new passenger';
                  } else {
                    return passenger.isPassengersGroup ? `${passenger.groupName}` : `${passenger.name} ${passenger.firstname}`;
                  }
                }
              }
              renderInput={(params) => props.passengersLoading ? <CircularProgress /> : <TextField {...params} label={props.passenger?.isPassengersGroup ? 'Passenger group name' : 'Passenger'} />}
              classes={{
                paper: classes.root,
              }}
              onChange={
                (event, newValue) => {
                  if (newValue === null) {
                    props.setPassenger(newValue);
                  } else if (newValue.id === null) {
                    setOpenModal(true);
                  } else {
                    props.setPassenger(newValue);
                  }
                }
              }
            />
          </Grid>
        </Grid>
        <Grid item xs={12}>
          <Grid container spacing={1}>
            <TransportInformation
              transportType={props.transportType || ''}
              setTransportType={props.setTransportType}
              flightInformation={props.flightInformation}
              setFlightInformation={props.setFlightInformation}
              trainInformation={props.trainInformation}
              setTrainInformation={props.setTrainInformation}
            />
          </Grid>
        </Grid>
        <Grid item xs={12}>
          {
            props.vehicles && (
              <VehicleSelector
                selectedVehicle={props.selectedVehicle}
                setSelectedVehicle={(vehicleId: number) => props.setSelectedVehicle(vehicleId)}
                options={props.vehicles}
              />
            )
          }
          {
            selectedVehicleDetails !== null &&
            props.passenger !== null &&
            props.passenger.isPassengersGroup &&
            props.passenger.groupSize &&
            props.passenger.groupSize > selectedVehicleDetails.numberSeats! &&
            <div style={{ marginTop: '15px' }}>
              Selected vehicle has less seats than the group size
            </div>
          }
        </Grid>
        <DialogActions>
          <Typography>
            {`Price: ${formatPrice(price, props.priceline?.currency.symbol || '£')}`}
          </Typography>
          {
            props.passenger !== null && initialPassenger !== null &&
            <>
              <Button
                onClick={() => handleValidate()}
                color="primary"
                autoFocus
                variant="outlined"
                style={{ marginLeft: '30px' }}
                disabled={props.passenger.id !== initialPassenger.id && props.alreadyTakenPassengersIds.includes(props.passenger.id)}
              >
                Validate
              </Button>
              <div>
                {
                  props.passenger.id !== initialPassenger.id && props.alreadyTakenPassengersIds.includes(props.passenger.id) &&
                  <div
                    style={{
                      display: 'flex',
                      justifyContent: 'center',
                      width: '100%',
                    }}
                  >
                    This passenger already has a Daily Disposal
                  </div>
                }
              </div>
            </>
          }
        </DialogActions>
      </DialogContent>
      <AddPassengerModal
        opened={openModal}
        setOpened={setOpenModal}
        setPassenger={(value: PassengerFragment) => { props.setPassenger(value); }}
        refetch={props.refetch}
      />
    </Dialog >
  );
});

export default EditAirportLineModal;