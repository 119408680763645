/* Framework imports ----------------------------------- */
import PageLayout from 'layout/PageLayout/PageLayout';
import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import config from 'config/config.json';
import { Button, Checkbox, FormControlLabel, Grid, TextField } from '@material-ui/core';
import DatePicker from 'components/DatePicker/DatePicker';
import { Alert } from '@material-ui/lab';
import useConfirmRideStyles from './ConfirmRideStyles';

/* Module imports -------------------------------------- */

/* Component imports ----------------------------------- */

/* ConfirmRide component prop types -------------------- */
interface ConfirmRideProps { }

interface FormValues {
  endDate: string;
  endTime: string;
  endDistance: number;
  endPlace: string;
  nextDate?: string | null;
  nextTime?: string | null;
  nextPlace?: string;
}

interface ResponseValues {
  distance: number;
  place: string;
  date: string;
  confirmed: boolean;
  nextDate: string | null;
  needsKilometers: boolean;
}

/* ConfirmRide component ------------------------------- */
const ConfirmRide: React.FC<ConfirmRideProps> = () => {
  const classes = useConfirmRideStyles();

  const { rideId, token } = useParams<{ rideId: string, token: string }>();
  const [formValues, setFormValues] = useState<FormValues>({
    endDate: '',
    endTime: '',
    endDistance: 0,
    endPlace: '',
    nextDate: new Date().toISOString(),
    nextTime: new Date().toLocaleTimeString([], { hour: 'numeric', minute: 'numeric' }),
    nextPlace: '',
  });
  const [nextInformationsDisplay, setNextInformationsDisplay] = useState<boolean>(false);
  const [ needsKilometers, setNeedsKilometers ] = useState<boolean>(false);
  const [alreadyConfirmed, setAlreadyConfirmed] = useState<boolean>(false);
  const [error, setError] = useState<boolean>(false);
  const [success, setSuccess] = useState<boolean>(false);
  const [submitted, setSubmitted] = useState<boolean>(false);

  useEffect(() => {
    if (rideId !== undefined && token !== undefined) {
      fetch(`${config.ODOO_HOST}/ride/${rideId}`, {
        method: 'GET',
        headers: {
          Accept: 'application/json',
          Authorization: `Bearer ${token}`
        }
      }).then((response) => {
        response.json().then((response) => {
          if (response !== undefined && response !== null) {
            const responseValues = response as ResponseValues;
            setFormValues({
              endDate: responseValues.date,
              endTime: new Date(responseValues.date.concat('Z')).toLocaleTimeString([], { hour: 'numeric', minute: 'numeric' }),
              endPlace: responseValues.place,
              endDistance: responseValues.distance,
              nextDate: responseValues.nextDate !== null ? responseValues.nextDate : null,
              nextTime: responseValues.nextDate !== null ? new Date(responseValues.nextDate.concat('Z')).toLocaleTimeString([], { hour: 'numeric', minute: 'numeric' }) : null,
              nextPlace: '',
            });
            setAlreadyConfirmed(responseValues.confirmed);
            setNeedsKilometers(responseValues.needsKilometers);
          }
        }).catch((error) => {
          console.error(error);
        });
      }).catch((error) => {
        console.error(error);
      });
    }
  }, [rideId, token]);

  const confirmRide = (): void => {
    const endTimeParts = formValues.endTime.split(':');
    const endDate = new Date(formValues.endDate);
    endDate.setHours(parseInt(endTimeParts[0]), parseInt(endTimeParts[1]));
    let body = {};
    if (
      nextInformationsDisplay &&
      formValues.nextDate !== undefined &&
      formValues.nextDate !== null &&
      formValues.nextTime !== undefined &&
      formValues.nextTime !== null
    ) {
      const nextTimeParts = formValues.nextTime.split(':');
      const nextDate = new Date(formValues.nextDate);
      nextDate.setHours(parseInt(nextTimeParts[0]), parseInt(nextTimeParts[1]));
      body = {
        ...formValues,
        endDate: endDate.toISOString().replace('T', ' ').split('.')[0],
        nextDate: nextDate.toISOString().replace('T', ' ').split('.')[0],
      };
    } else {
      body = {
        endDistance: formValues.endDistance,
        endPlace: formValues.endPlace,
        endDate: endDate.toISOString().replace('T', ' ').split('.')[0],
      };
    }
    fetch(`${config.ODOO_HOST}/ride/${rideId}`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Accept: 'application/json',
        Authorization: `Bearer ${token}`
      },
      body: JSON.stringify(body),
    }).then((response) => {
      response.json().then((response) => {
        if (response.error !== undefined) {
          setError(true);
        } else {
          setSuccess(true);
          setSubmitted(true);
        }
      }).catch((error) => {
        setError(true);
        console.error(error);
      });
    }).catch((error) => {
      setError(true);
      console.error(error);
    });
  };

  return (
    <PageLayout
      title="Confirm the ride"
      footer={false}
    >
      {
        error &&
        <Alert
          style={{ marginTop: '150px' }}
          severity="error"
          onClose={() => { setError(false); }}
        >
          An error has occured, please retry
        </Alert>
      }
      {
        success &&
        <Alert
          style={{ marginTop: '150px' }}
          severity="success"
          onClose={() => { setSuccess(false); }}
        >
          Your ride has been confirmed
        </Alert>
      }
      <Grid container spacing={2} style={{ display: 'flex', alignItems: 'center', flexDirection: 'column', marginTop: success || error ? '20px' : '150px' }}>
        <div style={{ display: 'flex', justifyContent: 'start', fontSize: 24, fontWeight: 'bold', textAlign: 'left', alignSelf: 'start', marginBottom: '15px' }}>
          Current ride informations
        </div>
        <DatePicker
          className={classes.input}
          disableToolbar
          variant="inline"
          format="dd/MM/yyyy"
          margin="none"
          id="date"
          label="End date"
          value={formValues?.endDate}
          onChange={(date) => date !== null && setFormValues({ ...formValues, endDate: date.toISOString() })}
          inputVariant="outlined"
          InputLabelProps={{
            shrink: true,
          }}
          required
          fullWidth
          disabled={submitted || alreadyConfirmed}
        />
        <TextField
          className={classes.input}
          label="End time"
          type="time"
          value={formValues.endTime}
          onChange={(event) => setFormValues({ ...formValues, endTime: event.target.value })}
          InputLabelProps={{
            shrink: true,
          }}
          variant="outlined"
          fullWidth
          required
          disabled={submitted || alreadyConfirmed}
        />
        {needsKilometers && <TextField
          label="End kilometers"
          className={classes.input}
          InputLabelProps={{
            shrink: true,
          }}
          type="number"
          value={formValues?.endDistance}
          onChange={(event) => setFormValues({ ...formValues, endDistance: parseFloat(event.target.value) })}
          variant="outlined"
          required
          InputProps={{
            inputProps: {
              min: 0
            }
          }}
          fullWidth
          disabled={submitted || alreadyConfirmed}
        />}
        <TextField
          className={classes.input}
          label="End place"
          InputLabelProps={{
            shrink: true,
          }}
          value={formValues?.endPlace}
          onChange={(event) => setFormValues({ ...formValues, endPlace: event.target.value })}
          variant="outlined"
          required
          fullWidth
          disabled={submitted || alreadyConfirmed}
        />
        {formValues.nextDate !== null &&
          <>
            <div style={{ display: 'flex', justifyContent: 'start', fontSize: 24, fontWeight: 'bold', textAlign: 'left', alignSelf: 'start', marginBottom: '15px' }}>
              Next ride informations
            </div>
            <FormControlLabel
              className={classes.input}
              control={
                <Checkbox
                  className={classes.input}
                  onClick={() => setNextInformationsDisplay(!nextInformationsDisplay)}
                />
              }
              label="I want to set my next ride informations"
              labelPlacement="end"
              style={{ alignSelf: 'start', marginBottom: '15px' }}
              disabled={submitted || alreadyConfirmed}
            />
            {
              nextInformationsDisplay &&
              <>
                <DatePicker
                  className={classes.input}
                  disableToolbar
                  variant="inline"
                  format="dd/MM/yyyy"
                  margin="none"
                  id="date"
                  label="Next date"
                  value={formValues?.nextDate}
                  onChange={(date) => date !== null && setFormValues({ ...formValues, nextDate: date.toISOString() })}
                  inputVariant="outlined"
                  InputLabelProps={{
                    shrink: true,
                  }}
                  fullWidth
                  disabled={submitted || alreadyConfirmed}
                />
                <TextField
                  className={classes.input}
                  label="Next time"
                  type="time"
                  value={formValues.nextTime}
                  onChange={(event) => setFormValues({ ...formValues, nextTime: event.target.value })}
                  InputLabelProps={{
                    shrink: true,
                  }}
                  variant="outlined"
                  fullWidth
                  disabled={submitted || alreadyConfirmed}
                />
                <TextField
                  className={classes.input}
                  label="Next place"
                  InputLabelProps={{
                    shrink: true,
                  }}
                  value={formValues?.nextPlace}
                  onChange={(event) => setFormValues({ ...formValues, nextPlace: event.target.value })}
                  variant="outlined"
                  fullWidth
                  disabled={submitted || alreadyConfirmed}
                />
              </>
            }
          </>
        }
        {
          !alreadyConfirmed ?
            <Button
              className={classes.button}
              onClick={confirmRide}
              variant="outlined"
              color="primary"
              disabled={
                submitted ||
                isNaN(formValues.endDistance) ||
                formValues.endPlace.length === 0
              }
              style={{ marginTop: '20px' }}
            >
              Validate
            </Button>
            :
            <div style={{ display: 'flex', justifyContent: 'center', marginTop: '20px', fontSize: 18 }}>
              This ride is already confirmed
            </div>
        }
      </Grid>
    </PageLayout>
  );
};

/* Export ConfirmRide component ------------------------ */
export default ConfirmRide;
