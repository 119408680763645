import { Grid } from '@material-ui/core';
import { useDeleteAirportLineMutation, useUpdateAirportLineMutation } from 'apollo/mutations';
import { useFindAirportAlreadyTakenPassengersQuery, useGetEventQuery, useListMyPassengersQuery } from 'apollo/queries';
import { CommunicorAirportFieldsFragment, PassengerFragment, VehicleFieldsFragment } from 'apollo/queries/types';
import { CommunicorAirportTemplate } from 'apollo/types';
import React, { useMemo, useRef, useState } from 'react';
import { FlightInformation } from 'type/FlightInformation';
import { TrainInformation } from 'type/TrainInformation';
import compile from 'utils/domain';
import { formatDateForSQL, formatLitteralDate, formatLitteralTime } from 'utils/formatDate';
import { formatPrice } from 'utils/formatPrice';
import CartItem from './CartItem';
import useCartItemCommonStyles from './CartItemCommonStyles';
import EditAirportLineModal from './EditAirportLineModal/EditAirportLineModal';
import * as Luq from 'luq';
import moment from 'moment-timezone';

interface Props {
  eventId?: number;
  basketId: string;
  line: CommunicorAirportFieldsFragment;
  currency: string | undefined;
  canDelete?: boolean;
  canEdit?: boolean;
  onDelete?: () => void;
  pricelist: Omit<CommunicorAirportTemplate, 'pricelistId'|'availableVehicleTypeIds'|'airportVehicleTypeIds'>[] | undefined;
}

const Airport: React.FC<Props> = ({
  eventId,
  basketId,
  line,
  currency,
  canDelete = true,
  canEdit = true,
  pricelist
}) => {
  const classes = useCartItemCommonStyles();

  const editAirportLineModal = useRef();

  const [transportType, setTransportType] = useState<string | undefined | null>(line.externalTransportType?.key);

  const [vehicleTypeId, setVehicleTypeId] = useState<number>(line.vehicleTypeId.id);

  const [selectedDate, setSelectedDate] = useState<string>(line.startDate ? line.startDate : line.endDate || '');

  const [passenger, setPassenger] = useState<PassengerFragment | null | undefined>(line.passengerId);

  const { data: eventData } = useGetEventQuery({ variables: { eventId : eventId as number }, skip: eventId === undefined });

  const { data: passengersData, loading: passengersLoading, error: passengersError, refetch } = useListMyPassengersQuery();
  const { data: alreadyTakenPassengersData, loading: alreadyTakenPassengersLoading } = useFindAirportAlreadyTakenPassengersQuery({
    variables: {
      filter: compile(
        <Luq.And>
          <Luq.Eq field="airport_ids.basket_id" value={parseInt(basketId)}></Luq.Eq>
          <Luq.Neq field={line.startDate !== null ? 'airport_ids.start_date' : 'airport_ids.end_date'} value={null} />
        </Luq.And>
      )
    },
    fetchPolicy: 'network-only',
    skip: basketId === undefined
  });

  const alreadyTakenPassengersIds = useMemo(() => {
    if (alreadyTakenPassengersData !== undefined && !alreadyTakenPassengersLoading) {
      return alreadyTakenPassengersData.searchResUsers.map((passenger) => passenger.id);
    } else {
      return [];
    }
  }, [alreadyTakenPassengersData, alreadyTakenPassengersLoading]);

  const [lineEditFlight, setLineEditFlight] = useState<FlightInformation>({
    transportCode: (transportType === 'flight' && line.transportCode) ? line.transportCode : '',
    transportNumber: (transportType === 'flight' && line.transportNumber) ? line.transportNumber : '',
    transportOrigin: (transportType === 'flight' && line.transportOrigin) ? line.transportOrigin : '',
    transportDestination: (transportType === 'flight' && line.transportDestination) ? line.transportDestination : ''
  });

  const [lineEditTrain, setLineEditTrain] = useState<TrainInformation>({
    transportNumber: (transportType === 'train' && line.transportNumber) ? line.transportNumber : '',
    transportOrigin: (transportType === 'train' && line.transportOrigin) ? line.transportOrigin : '',
    transportDestination: (transportType === 'train' && line.transportDestination) ? line.transportDestination : ''
  });

  const [deleteAirportLineMutation] = useDeleteAirportLineMutation();

  const onDelete = async () => {
    try {
      await deleteAirportLineMutation({
        variables: {
          id: line.id
        },
        update(cache) {
          const id = cache.identify({
            __typename: 'CommunicorAirport',
            id: line.id
          });
          cache.evict({ id: id });
          cache.gc();
        }
      });
    } catch (err) {
      console.log(err);
    }
  };

  const onEdit = () => {
    if (editAirportLineModal && editAirportLineModal.current) {
      // @ts-ignore
      editAirportLineModal.current.open();
    }
  };

  const [updateAirportLineMutation] = useUpdateAirportLineMutation();

  const currentPriceline = pricelist?.find((line) => line.vehicleTypeId.id === vehicleTypeId);

  const handleEdit = async () => {
    if (currentPriceline && passenger !== null && passenger !== undefined) {
      const transportInformation: FlightInformation | TrainInformation = transportType === 'flight' ? lineEditFlight : lineEditTrain;
      const [ day, month, year ] = new Date(selectedDate).toLocaleDateString().split('/');
      const [ hour, minute ]: string[] = new Date(selectedDate).toLocaleTimeString().split(':');
      const offset = -(eventData?.getCommunicorEvent?.tz?.value ? (moment.tz.zone(eventData?.getCommunicorEvent?.tz?.value)?.utcOffset(new Date(selectedDate).getTime()) || 0)/60 : 0);
      const offsetHours = Math.floor(Math.abs(offset));
      const offsetMinutes = (Math.abs(offset) - offsetHours) * 60;

      const localDateTime = new Date(`${year}-${month}-${day}T${hour}:${minute}:00.000${offset >= 0 ? '+' : '-'}${Math.abs(offsetHours) >= 10 ? '' + Math.abs(offsetHours) : '0' + Math.abs(offsetHours)}:${Math.abs(offsetMinutes) >= 10 ? '' + Math.abs(offsetMinutes) : '0' + Math.abs(offsetMinutes)}`);
      try {
        await updateAirportLineMutation({
          variables: {
            id: line.id,
            vehicle: vehicleTypeId,
            passengerId: passenger.id,
            [line.startDate ? 'startDate' : 'endDate']: formatDateForSQL(localDateTime),
            template: currentPriceline.id,
            externalTransportType: transportType || '',
            ...transportInformation
          }
        });
      } catch (err) {
        console.log(err);
      }
    }

  };

  const vehicles = pricelist?.reduce((acc: VehicleFieldsFragment[], value) => {
    acc.push(value.vehicleTypeId);
    return acc;
  }, []);

  return (
    <CartItem
      title={`${line.externalTransportType?.key === 'flight' ? 'Airport' : 'City'} Transfer - ${line.startDate ? 'Arrival' : 'Departure'}`}
      price={line.pricePerUnit !== undefined ? formatPrice(line.pricePerUnit * line.quantity, currency || '£') : ''}
      canDelete={canDelete}
      canEdit={canEdit}
      onDelete={onDelete}
      onEdit={onEdit}
    >
      <Grid item xs={12} className={classes.title}>
        Vehicle Type :
      </Grid>
      <Grid item xs={12} className={classes.content}>
        {line.vehicleTypeId.name}
      </Grid>
      <Grid item xs={12} className={classes.title}>
        {`${line.startDate ? 'Arriving Date' : 'Date of Departure'}:`}
      </Grid>
      <Grid item xs={12} className={classes.content}>
        {formatLitteralDate(line.startDate ? line.startDate : line.endDate || '')}
      </Grid>
      <Grid item xs={12} className={classes.title}>
        {`${line.startDate ? 'Arriving Time' : 'Time of Departure'}:`}
      </Grid>
      <Grid item xs={12} className={classes.content}>
        {formatLitteralTime(line.startDate ? line.startDate : line.endDate || '', eventData?.getCommunicorEvent?.tz.value)}
      </Grid>
      {line.passengerId !== undefined && line.passengerId !== null &&
        <>
          <Grid item xs={12} className={classes.title}>
            Passenger {line.passengerId.isPassengersGroup && 'Group'} Name :
          </Grid>
          <Grid item xs={12} className={classes.content}>
            {line.passengerId.isPassengersGroup ? line.passengerId.groupName : `${line.passengerId.name} ${line.passengerId.firstname}`}
          </Grid>
        </>
      }
      {
        line.externalTransportType?.key === 'flight' && (
          <>
            <Grid item xs={6} className={classes.title}>
              Airport Code
            </Grid>
            <Grid item xs={6} className={classes.title}>
              Flight Number
            </Grid>
            <Grid item xs={6} className={classes.content}>
              {line.transportCode || 'NC'}
            </Grid>
            <Grid item xs={6} className={classes.content}>
              {line.transportNumber || 'NC'}
            </Grid>
            <Grid item xs={6} className={classes.title}>
              Port of Origin
            </Grid>
            <Grid item xs={6} className={classes.title}>
              Port of Arrival
            </Grid>
            <Grid item xs={6} className={classes.content}>
              {line.transportOrigin || 'NC'}
            </Grid>
            <Grid item xs={6} className={classes.content}>
              {line.transportDestination || 'NC'}
            </Grid>
          </>
        )
      }
      {
        line.externalTransportType?.key === 'train' && (
          <>
            <Grid item xs={12} className={classes.title}>
              Train Number
            </Grid>
            <Grid item xs={12} className={classes.content}>
              {line.transportNumber || 'NC'}
            </Grid>
            <Grid item xs={6} className={classes.title}>
              Station of Departure
            </Grid>
            <Grid item xs={6} className={classes.title}>
              Station of Arrival
            </Grid>
            <Grid item xs={6} className={classes.content}>
              {line.transportOrigin || 'NC'}
            </Grid>
            <Grid item xs={6} className={classes.content}>
              {line.transportDestination || 'NC'}
            </Grid>
          </>
        )
      }
      {passenger !== undefined &&
        <EditAirportLineModal
          flightInformation={lineEditFlight}
          setFlightInformation={setLineEditFlight}
          trainInformation={lineEditTrain}
          setTrainInformation={setLineEditTrain}
          selectedDate={selectedDate}
          setSelectedDate={setSelectedDate}
          tzValue={eventData?.getCommunicorEvent?.tz.value}
          selectedVehicle={vehicleTypeId}
          setSelectedVehicle={setVehicleTypeId}
          transportType={transportType}
          setTransportType={setTransportType}
          onValidate={handleEdit}
          ref={editAirportLineModal}
          passenger={passenger}
          setPassenger={setPassenger}
          vehicles={vehicles}
          priceline={currentPriceline}
          alreadyTakenPassengersIds={alreadyTakenPassengersIds}
          passengersList={passengersData}
          passengersLoading={passengersLoading}
          refetch={refetch}
        />}
    </CartItem>
  );
};

export default Airport;